"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Crutch = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M507.31 185.71l-181-181a16 16 0 00-22.62 0L281 27.31a16 16 0 000 22.63l181 181a16 16 0 0022.63 0l22.62-22.63a16 16 0 00.06-22.6zm-179.54 66.41l-67.89-67.89 55.1-55.1-45.25-45.25-109.67 109.67a96.08 96.08 0 00-25.67 46.29L106.65 360.1l-102 102a16 16 0 000 22.63l22.62 22.62a16 16 0 0022.63 0l102-102 120.25-27.75a95.88 95.88 0 0046.29-25.65l109.68-109.68L382.87 197zm-54.57 54.57a32 32 0 01-15.45 8.54l-79.3 18.32 18.3-79.3a32.22 32.22 0 018.56-15.45l9.31-9.31 67.89 67.89z", key: "k0" })));
});
exports.Crutch.displayName = 'Crutch';
exports.CrutchDimensions = { height: undefined, width: undefined };
