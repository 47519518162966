"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.ChessRook = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 384 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M368 32h-56a16 16 0 00-16 16v48h-48V48a16 16 0 00-16-16h-80a16 16 0 00-16 16v48H88.1V48a16 16 0 00-16-16H16A16 16 0 000 48v176l64 32c0 48.33-1.54 95-13.21 160h282.42C321.54 351 320 303.72 320 256l64-32V48a16 16 0 00-16-16zM224 320h-64v-64a32 32 0 0164 0zm144 128H16a16 16 0 00-16 16v32a16 16 0 0016 16h352a16 16 0 0016-16v-32a16 16 0 00-16-16z", key: "k0" })));
});
exports.ChessRook.displayName = 'ChessRook';
exports.ChessRookDimensions = { height: undefined, width: undefined };
