"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Meteor = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M511.328 20.803c-11.608 38.702-34.307 111.701-61.303 187.7 6.999 2.094 13.404 4 18.606 5.594a16.062 16.062 0 019.499 22.906c-22.106 42.297-82.69 152.795-142.478 214.404-1 1.093-2 2.5-3 3.5A194.83 194.83 0 1157.085 179.41c1-1 2.406-2 3.5-3 61.6-59.905 171.973-120.405 214.373-142.498a16.058 16.058 0 0122.903 9.5c1.593 5.093 3.5 11.593 5.593 18.593 75.894-27 148.977-49.702 187.674-61.296a16.183 16.183 0 0120.2 20.094zm-191.377 299.2A127.98 127.98 0 10191.971 448a127.976 127.976 0 00127.98-127.998zm-127.98-32a31.995 31.995 0 11-31.996-32 31.959 31.959 0 0131.996 32zM223.966 368a15.998 15.998 0 11-15.998-16 16.05 16.05 0 0115.998 16z", key: "k0" })));
});
exports.Meteor.displayName = 'Meteor';
exports.MeteorDimensions = { height: undefined, width: undefined };
