"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.PhotoVideo = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M608 0H160a32 32 0 00-32 32v96h160V64h192v320h128a32 32 0 0032-32V32a32 32 0 00-32-32zM232 103a9 9 0 01-9 9h-30a9 9 0 01-9-9V73a9 9 0 019-9h30a9 9 0 019 9zm352 208a9 9 0 01-9 9h-30a9 9 0 01-9-9v-30a9 9 0 019-9h30a9 9 0 019 9zm0-104a9 9 0 01-9 9h-30a9 9 0 01-9-9v-30a9 9 0 019-9h30a9 9 0 019 9zm0-104a9 9 0 01-9 9h-30a9 9 0 01-9-9V73a9 9 0 019-9h30a9 9 0 019 9zm-168 57H32a32 32 0 00-32 32v288a32 32 0 0032 32h384a32 32 0 0032-32V192a32 32 0 00-32-32zM96 224a32 32 0 11-32 32 32 32 0 0132-32zm288 224H64v-32l64-64 32 32 128-128 96 96z", key: "k0" })));
});
exports.PhotoVideo.displayName = 'PhotoVideo';
exports.PhotoVideoDimensions = { height: undefined, width: undefined };
