"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.HandSpock = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M501.03 116.176c-19.39-31.508-51.244-35.728-66.31-35.018-14.113-50.81-62.004-54.08-70.738-54.08a74.03 74.03 0 00-72.238 58.916l-4.647 22.66-13.683-53.206c-9.096-35.371-46.412-64.051-89.66-53.073a73.897 73.897 0 00-55.121 78.948 73.683 73.683 0 00-64.85 94.421l24.36 82.198c-38.24-7.545-62.797 16.183-68.116 21.847a73.68 73.68 0 003.2 104.194l91.365 85.976A154.164 154.164 0 00220.622 512h107.456a127.3 127.3 0 00124.261-98.139L509.962 171.9a73.203 73.203 0 00-8.931-55.723zm-37.76 44.605l-57.622 241.967a79.466 79.466 0 01-77.57 61.26H220.623a106.34 106.34 0 01-73.137-28.998l-91.369-85.98c-24.773-23.303 10.494-60.899 35.28-37.49l51.122 48.107c5.426 5.109 13.483.716 13.483-5.827a246.8 246.8 0 00-10.178-70.152L109.811 162.13c-9.733-32.883 39.699-47.271 49.386-14.625l31.344 105.78c5.594 18.904 33.781 10.712 28.965-8.008l-42.442-165.04c-8.504-33.103 41.432-45.646 49.865-12.835l47.327 184.035c4.427 17.242 29.162 16.504 32.71-.805l31.791-154.97c6.81-33.108 57.518-24.108 50.12 11.962l-28.55 139.166c-3.722 18.11 23.669 24.631 28.057 6.217l24.801-104.146c7.965-33.348 57.955-21.063 50.086 11.921z", key: "k0" })));
});
exports.HandSpock.displayName = 'HandSpock';
exports.HandSpockDimensions = { height: undefined, width: undefined };
