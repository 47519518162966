"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.ObjectUngroup = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 576 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M64 320v26a6 6 0 01-6 6H6a6 6 0 01-6-6v-52a6 6 0 016-6h26V96H6a6 6 0 01-6-6V38a6 6 0 016-6h52a6 6 0 016 6v26h288V38a6 6 0 016-6h52a6 6 0 016 6v52a6 6 0 01-6 6h-26v192h26a6 6 0 016 6v52a6 6 0 01-6 6h-52a6 6 0 01-6-6v-26H64zm480-64v-32h26a6 6 0 006-6v-52a6 6 0 00-6-6h-52a6 6 0 00-6 6v26H408v72h8c13.255 0 24 10.745 24 24v64c0 13.255-10.745 24-24 24h-64c-13.255 0-24-10.745-24-24v-8H192v72h-26a6 6 0 00-6 6v52a6 6 0 006 6h52a6 6 0 006-6v-26h288v26a6 6 0 006 6h52a6 6 0 006-6v-52a6 6 0 00-6-6h-26V256z", key: "k0" })));
});
exports.ObjectUngroup.displayName = 'ObjectUngroup';
exports.ObjectUngroupDimensions = { height: undefined, width: undefined };
