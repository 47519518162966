"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.ChessKing = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M400 448H48a16 16 0 00-16 16v32a16 16 0 0016 16h352a16 16 0 0016-16v-32a16 16 0 00-16-16zm16-288H256v-48h40a8 8 0 008-8V56a8 8 0 00-8-8h-40V8a8 8 0 00-8-8h-48a8 8 0 00-8 8v40h-40a8 8 0 00-8 8v48a8 8 0 008 8h40v48H32a32 32 0 00-30.52 41.54L74.56 416h298.88l73.08-214.46A32 32 0 00416 160z", key: "k0" })));
});
exports.ChessKing.displayName = 'ChessKing';
exports.ChessKingDimensions = { height: undefined, width: undefined };
