"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Caravan = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M416 208a16 16 0 1016 16 16 16 0 00-16-16zm208 112h-48V160A160 160 0 00416 0H64A64 64 0 000 64v256a64 64 0 0064 64h32a96 96 0 00192 0h336a16 16 0 0016-16v-32a16 16 0 00-16-16zM192 432a48 48 0 1148-48 48.05 48.05 0 01-48 48zm64-240a32 32 0 01-32 32H96a32 32 0 01-32-32v-64a32 32 0 0132-32h128a32 32 0 0132 32zm192 128H320V128a32 32 0 0132-32h64a32 32 0 0132 32z", key: "k0" })));
});
exports.Caravan.displayName = 'Caravan';
exports.CaravanDimensions = { height: undefined, width: undefined };
