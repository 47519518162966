"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Plug = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 384 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M320 32a32 32 0 00-64 0v96h64zm48 128H16a16 16 0 00-16 16v32a16 16 0 0016 16h16v32a160.07 160.07 0 00128 156.8V512h64v-99.2A160.07 160.07 0 00352 256v-32h16a16 16 0 0016-16v-32a16 16 0 00-16-16zM128 32a32 32 0 00-64 0v96h64z", key: "k0" })));
});
exports.Plug.displayName = 'Plug';
exports.PlugDimensions = { height: undefined, width: undefined };
