"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.SortNumericUpAlt = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M107.31 36.69a16 16 0 00-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0016 16h32a16 16 0 0016-16V160h48c14.21 0 21.38-17.24 11.31-27.31zM400 416h-16V304a16 16 0 00-16-16h-48a16 16 0 00-14.29 8.83l-16 32A16 16 0 00304 352h16v64h-16a16 16 0 00-16 16v32a16 16 0 0016 16h96a16 16 0 0016-16v-32a16 16 0 00-16-16zM330.17 34.91a79 79 0 00-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 01-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.77 86.25-61.61 86.25-132V112c-.02-51.21-48.4-91.34-101.85-77.09zM352 132a20 20 0 1120-20 20 20 0 01-20 20z", key: "k0" })));
});
exports.SortNumericUpAlt.displayName = 'SortNumericUpAlt';
exports.SortNumericUpAltDimensions = { height: undefined, width: undefined };
