"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.FileCode = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 384 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M384 121.941V128H256V0h6.059c6.365 0 12.47 2.529 16.971 7.029l97.941 97.941A24.005 24.005 0 01384 121.941zM248 160c-13.2 0-24-10.8-24-24V0H24C10.745 0 0 10.745 0 24v464c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V160H248zM123.206 400.505a5.4 5.4 0 01-7.633.246l-64.866-60.812a5.4 5.4 0 010-7.879l64.866-60.812a5.4 5.4 0 017.633.246l19.579 20.885a5.4 5.4 0 01-.372 7.747L101.65 336l40.763 35.874a5.4 5.4 0 01.372 7.747l-19.579 20.884zm51.295 50.479l-27.453-7.97a5.402 5.402 0 01-3.681-6.692l61.44-211.626a5.402 5.402 0 016.692-3.681l27.452 7.97a5.4 5.4 0 013.68 6.692l-61.44 211.626a5.397 5.397 0 01-6.69 3.681zm160.792-111.045l-64.866 60.812a5.4 5.4 0 01-7.633-.246l-19.58-20.885a5.4 5.4 0 01.372-7.747L284.35 336l-40.763-35.874a5.4 5.4 0 01-.372-7.747l19.58-20.885a5.4 5.4 0 017.633-.246l64.866 60.812a5.4 5.4 0 01-.001 7.879z", key: "k0" })));
});
exports.FileCode.displayName = 'FileCode';
exports.FileCodeDimensions = { height: undefined, width: undefined };
