"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Cheese = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M0 288v160a32 32 0 0032 32h448a32 32 0 0032-32V288zM299.83 32a32 32 0 00-21.13 7L0 256h512c0-119.89-94-217.8-212.17-224z", key: "k0" })));
});
exports.Cheese.displayName = 'Cheese';
exports.CheeseDimensions = { height: undefined, width: undefined };
