"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.HandSpock = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M510.9 145.27l-68.296 286.824A103.995 103.995 0 01341.437 512H214.074a135.97 135.97 0 01-93.185-36.953L12.591 373.127a39.992 39.992 0 0154.812-58.25l60.593 57.026a283.248 283.248 0 00-11.67-80.468L73.637 147.36a40.006 40.006 0 1176.719-22.719l37.154 125.395a8.331 8.331 0 0016.057-4.441l-50.305-195.64a39.996 39.996 0 1177.468-19.938l56.095 218.158a10.42 10.42 0 0020.3-.501L344.808 63.97a40.052 40.052 0 0151.302-30.09c19.86 6.3 30.863 27.674 26.676 48.085l-33.839 164.966a7.552 7.552 0 0014.744 3.267l29.397-123.459a39.994 39.994 0 1177.813 18.531z", key: "k0" })));
});
exports.HandSpock.displayName = 'HandSpock';
exports.HandSpockDimensions = { height: undefined, width: undefined };
