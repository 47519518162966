"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Fax = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M480 160V77.25a32 32 0 00-9.38-22.63L425.37 9.37A32 32 0 00402.75 0H160a32 32 0 00-32 32v448a32 32 0 0032 32h320a32 32 0 0032-32V192a32 32 0 00-32-32zM288 432a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm128 128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-128a16 16 0 01-16 16h-32a16 16 0 01-16-16v-32a16 16 0 0116-16h32a16 16 0 0116 16zm0-112H192V64h160v48a16 16 0 0016 16h48zM64 128H32a32 32 0 00-32 32v320a32 32 0 0032 32h32a32 32 0 0032-32V160a32 32 0 00-32-32z", key: "k0" })));
});
exports.Fax.displayName = 'Fax';
exports.FaxDimensions = { height: undefined, width: undefined };
