"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Mouse = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 384 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M0 352a160 160 0 00160 160h64a160 160 0 00160-160V224H0zM176 0h-16A160 160 0 000 160v32h176zm48 0h-16v192h176v-32A160 160 0 00224 0z", key: "k0" })));
});
exports.Mouse.displayName = 'Mouse';
exports.MouseDimensions = { height: undefined, width: undefined };
