"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Superscript = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M496 160h-16V16a16 16 0 00-16-16h-48a16 16 0 00-14.29 8.83l-16 32A16 16 0 00400 64h16v96h-16a16 16 0 00-16 16v32a16 16 0 0016 16h96a16 16 0 0016-16v-32a16 16 0 00-16-16zM336 64h-67a16 16 0 00-13.14 6.87l-79.9 115-79.9-115A16 16 0 0083 64H16A16 16 0 000 80v48a16 16 0 0016 16h33.48l77.81 112-77.81 112H16a16 16 0 00-16 16v48a16 16 0 0016 16h67a16 16 0 0013.14-6.87l79.9-115 79.9 115A16 16 0 00269 448h67a16 16 0 0016-16v-48a16 16 0 00-16-16h-33.48l-77.81-112 77.81-112H336a16 16 0 0016-16V80a16 16 0 00-16-16z", key: "k0" })));
});
exports.Superscript.displayName = 'Superscript';
exports.SuperscriptDimensions = { height: undefined, width: undefined };
