"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Shapes = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M128 256a128 128 0 10128 128 128 128 0 00-128-128zm379-54.86L400.07 18.29a37.26 37.26 0 00-64.14 0L229 201.14c-14.24 24.38 3.58 54.86 32.09 54.86h213.82c28.51 0 46.33-30.48 32.09-54.86zM480 288H320a32 32 0 00-32 32v160a32 32 0 0032 32h160a32 32 0 0032-32V320a32 32 0 00-32-32z", key: "k0" })));
});
exports.Shapes.displayName = 'Shapes';
exports.ShapesDimensions = { height: undefined, width: undefined };
