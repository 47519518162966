"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Voicemail = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M496 128a144 144 0 00-119.74 224H263.74A144 144 0 10144 416h352a144 144 0 000-288zM64 272a80 80 0 1180 80 80 80 0 01-80-80zm432 80a80 80 0 1180-80 80 80 0 01-80 80z", key: "k0" })));
});
exports.Voicemail.displayName = 'Voicemail';
exports.VoicemailDimensions = { height: undefined, width: undefined };
