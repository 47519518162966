"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.PhoneSquareAlt = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M400 32H48A48 48 0 000 80v352a48 48 0 0048 48h352a48 48 0 0048-48V80a48 48 0 00-48-48zm-16.39 307.37l-15 65A15 15 0 01354 416C194 416 64 286.29 64 126a15.7 15.7 0 0111.63-14.61l65-15A18.23 18.23 0 01144 96a16.27 16.27 0 0113.79 9.09l30 70A17.9 17.9 0 01189 181a17 17 0 01-5.5 11.61l-37.89 31a231.91 231.91 0 00110.78 110.78l31-37.89A17 17 0 01299 291a17.85 17.85 0 015.91 1.21l70 30A16.25 16.25 0 01384 336a17.41 17.41 0 01-.39 3.37z", key: "k0" })));
});
exports.PhoneSquareAlt.displayName = 'PhoneSquareAlt';
exports.PhoneSquareAltDimensions = { height: undefined, width: undefined };
