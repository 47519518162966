"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.FolderPlus = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M464 128H272l-64-64H48a48 48 0 00-48 48v288a48 48 0 0048 48h416a48 48 0 0048-48V176a48 48 0 00-48-48zM359.5 296a16 16 0 01-16 16h-64v64a16 16 0 01-16 16h-16a16 16 0 01-16-16v-64h-64a16 16 0 01-16-16v-16a16 16 0 0116-16h64v-64a16 16 0 0116-16h16a16 16 0 0116 16v64h64a16 16 0 0116 16z", key: "k0" })));
});
exports.FolderPlus.displayName = 'FolderPlus';
exports.FolderPlusDimensions = { height: undefined, width: undefined };
