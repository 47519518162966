"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.RemoveFormat = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M336 416h-11.17l9.26-27.77L267 336.4 240.49 416H208a16 16 0 00-16 16v32a16 16 0 0016 16h128a16 16 0 0016-16v-32a16 16 0 00-16-16zm297.82 42.1L377 259.59 426.17 112H544v32a16 16 0 0016 16h32a16 16 0 0016-16V48a16 16 0 00-16-16H176a16 16 0 00-16 16v43.9L45.46 3.38A16 16 0 0023 6.19L3.37 31.46a16 16 0 002.81 22.45l588.36 454.72a16 16 0 0022.46-2.81l19.64-25.27a16 16 0 00-2.82-22.45zM309.91 207.76L224 141.36V112h117.83z", key: "k0" })));
});
exports.RemoveFormat.displayName = 'RemoveFormat';
exports.RemoveFormatDimensions = { height: undefined, width: undefined };
