"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.IceCream = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M368 160h-.94a144 144 0 10-286.12 0H80a48 48 0 000 96h288a48 48 0 000-96zM195.38 493.69a31.52 31.52 0 0057.24 0L352 288H96z", key: "k0" })));
});
exports.IceCream.displayName = 'IceCream';
exports.IceCreamDimensions = { height: undefined, width: undefined };
