"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Torah = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M320.05 366.48l17.72-29.64h-35.46zm99.21-166H382.4l18.46 30.82zM48 0C21.49 0 0 14.33 0 32v448c0 17.67 21.49 32 48 32s48-14.33 48-32V32C96 14.33 74.51 0 48 0zm172.74 311.5h36.85l-18.46-30.82zm161.71 0h36.86l-18.45-30.8zM128 464h384V48H128zm66.77-278.13a21.22 21.22 0 0118.48-10.71h59.45l29.13-48.71a21.13 21.13 0 0118.22-10.37A20.76 20.76 0 01338 126.29l29.25 48.86h59.52a21.12 21.12 0 0118.1 32L415.63 256 445 305a20.69 20.69 0 01.24 21.12 21.25 21.25 0 01-18.48 10.72h-59.47l-29.13 48.7a21.13 21.13 0 01-18.16 10.4 20.79 20.79 0 01-18-10.22l-29.25-48.88h-59.5a21.11 21.11 0 01-18.1-32L224.36 256 195 207a20.7 20.7 0 01-.23-21.13zM592 0c-26.51 0-48 14.33-48 32v448c0 17.67 21.49 32 48 32s48-14.33 48-32V32c0-17.67-21.49-32-48-32zM320 145.53l-17.78 29.62h35.46zm-62.45 55h-36.81l18.44 30.8zm29.58 111h65.79L386.09 256l-33.23-55.52h-65.79L253.9 256z", key: "k0" })));
});
exports.Torah.displayName = 'Torah';
exports.TorahDimensions = { height: undefined, width: undefined };
