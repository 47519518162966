"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Biking = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M400 96a48 48 0 10-48-48 48 48 0 0048 48zm-4 121a31.9 31.9 0 0020 7h64a32 32 0 000-64h-52.78L356 103a31.94 31.94 0 00-40.81.68l-112 96a32 32 0 003.08 50.92L288 305.12V416a32 32 0 0064 0V288a32 32 0 00-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 10128 128 128 128 0 00-128-128zm0 192a64 64 0 1164-64 64 64 0 01-64 64zM128 256a128 128 0 10128 128 128 128 0 00-128-128zm0 192a64 64 0 1164-64 64 64 0 01-64 64z", key: "k0" })));
});
exports.Biking.displayName = 'Biking';
exports.BikingDimensions = { height: undefined, width: undefined };
