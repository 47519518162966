"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.VolleyballBall = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M231.39 243.48a285.56 285.56 0 00-22.7-105.7c-90.8 42.4-157.5 122.4-180.3 216.8a249 249 0 0056.9 81.1 333.87 333.87 0 01146.1-192.2zm-36.9-134.4a284.23 284.23 0 00-57.4-70.7c-91 49.8-144.8 152.9-125 262.2 33.4-83.1 98.4-152 182.4-191.5zm187.6 165.1c8.6-99.8-27.3-197.5-97.5-264.4-14.7-1.7-51.6-5.5-98.9 8.5A333.87 333.87 0 01279.19 241a285 285 0 00102.9 33.18zm-124.7 9.5a286.33 286.33 0 00-80.2 72.6c82 57.3 184.5 75.1 277.5 47.8a247.15 247.15 0 0042.2-89.9 336.1 336.1 0 01-80.9 10.4c-54.6-.1-108.9-14.1-158.6-40.9zm-98.3 99.7c-15.2 26-25.7 54.4-32.1 84.2a247.07 247.07 0 00289-22.1c-112.9 16.1-203.3-24.8-256.9-62.1zm180.3-360.6c55.3 70.4 82.5 161.2 74.6 253.6a286.59 286.59 0 0089.7-14.2c0-2 .3-4 .3-6 0-107.8-68.7-199.1-164.6-233.4z", key: "k0" })));
});
exports.VolleyballBall.displayName = 'VolleyballBall';
exports.VolleyballBallDimensions = { height: undefined, width: undefined };
