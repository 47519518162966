/**
 * This file is a custom hack to enable better timezone handling in the OTP UI
 * 1. It formats the time in all queries in UTC.
 * 2. It interprets the depart at and arrive by times in the _agency's_ timezone.
 * 3. Leave now still means at this current moment, anywhere in the world.
 * 4. It shows stop times in the _agency's_ timezone.
 * 5. TODO: It displays agency local time in the date picker,
 *    rather than the internal UTC format.
 */
import moment from 'moment'
import coreUtils from '@opentripplanner/core-utils'
const { OTP_API_DATE_FORMAT, OTP_API_TIME_FORMAT } = coreUtils.time

export async function withTimezoneConfig (otpState) {
  const res = await fetch(getTimezoneQuery(otpState))
  const json = await getJsonAndCheckResponse(res)
  // *Responses* are always returned in UTC, then given to moment to convert
  // to the local timezone. This makes moment believe that the agency's
  // timezone is the local timezone.
  moment.tz.setDefault(json)
  return getTimezoneParams(otpState, json)
}

function getTimezoneQuery (otpState) {
  const api = otpState.config.api
  const tzEndpoint = `${api.host}${api.port ? ':' + api.port : ''}/v3/timezone`
  const from = otpState.currentQuery.from
  const query = `?lat=${from.lat}&lon=${from.lon}&format=json`
  return tzEndpoint + query
}

function getTimezoneParams (otpState, json) {
  // *Requests* are usually sent in browser local time, but Transit
  // violates this spec and expects UTC. Also to make the app more useful
  // for testing with different agencies, we pretend that times should be entered
  // in the agency's timezone
  let {date, time, departArrive} = otpState.currentQuery

  if (departArrive === 'NOW') {
    const now = moment.utc()
    date = now.format(OTP_API_DATE_FORMAT)
    time = now.format(OTP_API_TIME_FORMAT)
  } else {
    const dateTime = `${date} ${time}`
    const dateTimeFmt = `${OTP_API_DATE_FORMAT} ${OTP_API_TIME_FORMAT}`
    const dateTimeObj = moment(dateTime, dateTimeFmt)
    const dateTimeUtc = dateTimeObj.tz(json).utc()
    date = dateTimeUtc.format(OTP_API_DATE_FORMAT)
    time = dateTimeUtc.format(OTP_API_TIME_FORMAT)
  }
  return {date, time}
}

function getJsonAndCheckResponse (res) {
  if (res.status >= 400) {
    const error = new Error('Received error from server')
    error.response = res
    throw error
  }
  return res.json()
}
