"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Satellite = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M502.61 310.042l-96.704 96.716a31.882 31.882 0 01-45.008 0l-80.326-80.417-9.892 9.908a190.563 190.563 0 01-5.407 168.523c-4.501 8.5-16.394 9.595-23.208 2.797L134.547 400.054l-17.8 17.8c.703 2.61 1.61 5 1.61 7.798a32.005 32.005 0 11-32.006-32.005c2.798 0 5.189.907 7.799 1.61l17.8-17.8L4.43 269.943c-6.798-6.814-5.704-18.612 2.798-23.206a190.582 190.582 0 01168.528-5.407l9.799-9.799-80.31-80.417a32.002 32.002 0 010-45.1l96.719-96.716A31.626 31.626 0 01224.469 0a32 32 0 0122.597 9.298l80.326 80.308 47.805-47.897a33.608 33.608 0 0147.509 0l47.508 47.506a33.633 33.633 0 010 47.507l-47.805 47.897 80.31 80.417a31.79 31.79 0 01-.11 45.006zM219.562 197.433l73.825-73.823-68.918-68.9-73.81 73.807zm237.743 90.106l-68.902-68.916-73.825 73.823 68.918 68.9z", key: "k0" })));
});
exports.Satellite.displayName = 'Satellite';
exports.SatelliteDimensions = { height: undefined, width: undefined };
