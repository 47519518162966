"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.JournalWhills = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M438.406 377.594c-3.203 12.812-3.203 57.61 0 73.61q9.586 9.585 9.594 19.202v16c0 16-12.797 25.594-25.594 25.594H96c-54.406 0-96-41.594-96-96V96C0 41.594 41.594 0 96 0h326.406C438.406 0 448 9.594 448 25.594v332.812q0 14.39-9.594 19.188zM380.796 384H96c-16 0-32 12.797-32 32s12.797 32 32 32h284.797zm-252.78-207.984c0 .515.14.984.14 1.5l37.11 32.468A8 8 0 01160 224h-.016a9.177 9.177 0 01-5.25-1.984l-23.593-20.641C142.688 250.953 186.906 288 240 288s97.313-37.047 108.875-86.625l-23.594 20.64a8.025 8.025 0 01-5.265 1.97H320a9.146 9.146 0 01-6.016-2.72A9.265 9.265 0 01312 216a9.097 9.097 0 012.734-6.016l37.11-32.468c.015-.532.156-1 .156-1.516 0-11.047-2.094-21.516-5.063-31.594l-21.265 21.25a8.005 8.005 0 01-11.328-11.312l26.422-26.407a111.815 111.815 0 00-46.36-49.265 63.023 63.023 0 01-14.062 82.64 55.838 55.838 0 01-28.719 93.422l-1.422-34.28 12.672 8.624a3.967 3.967 0 002.25.688 3.98 3.98 0 003.437-6.032l-8.53-14.312 17.906-3.719a4.006 4.006 0 000-7.844l-17.907-3.718 8.531-14.313a3.98 3.98 0 00-3.437-6.031 4.726 4.726 0 00-2.25.672l-14.188 9.656L244 71.828a4.004 4.004 0 00-8 0l-4.625 110.813-12-8.157a4.003 4.003 0 00-5.688 5.36l8.532 14.312-17.906 3.719a3.997 3.997 0 000 7.828l17.906 3.735-8.532 14.296a4.725 4.725 0 00-.562 2.047 4.595 4.595 0 001.25 2.906 4.01 4.01 0 002.75 1.094 4.09 4.09 0 002.25-.687l10.36-7.047-1.36 32.719a55.864 55.864 0 01-28.719-93.453 63.011 63.011 0 01-14.047-82.657 111.932 111.932 0 00-46.375 49.266l26.422 26.422a8 8 0 01-11.312 11.312l-21.266-21.265c-2.984 10.093-5.078 20.562-5.062 31.625z", key: "k0" })));
});
exports.JournalWhills.displayName = 'JournalWhills';
exports.JournalWhillsDimensions = { height: undefined, width: undefined };
