"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.ChessQueen = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M256 112a56 56 0 10-56-56 56 56 0 0056 56zm176 336H80a16 16 0 00-16 16v32a16 16 0 0016 16h352a16 16 0 0016-16v-32a16 16 0 00-16-16zm72.87-263.84l-28.51-15.92c-7.44-5-16.91-2.46-22.29 4.68a47.59 47.59 0 01-47.23 18.23C383.7 186.86 368 164.93 368 141.4a13.4 13.4 0 00-13.4-13.4h-38.77c-6 0-11.61 4-12.86 9.91a48 48 0 01-93.94 0c-1.25-5.92-6.82-9.91-12.86-9.91H157.4a13.4 13.4 0 00-13.4 13.4c0 25.69-19 48.75-44.67 50.49a47.5 47.5 0 01-41.54-19.15c-5.28-7.09-14.73-9.45-22.09-4.54l-28.57 16a16 16 0 00-5.44 20.47L104.24 416h303.52l102.55-211.37a16 16 0 00-5.44-20.47z", key: "k0" })));
});
exports.ChessQueen.displayName = 'ChessQueen';
exports.ChessQueenDimensions = { height: undefined, width: undefined };
