"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _unitsCss = require("units-css");

var _unitsCss2 = _interopRequireDefault(_unitsCss);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_CANVAS = document.createElement("canvas"); /* eslint-env browser */

var DEFAULT_FONT_WEIGHT = 400;
var DEFAULT_FONT_STYLE = "normal";

var measureHeight = function measureHeight(size, lineHeight) {
  // If the line-height is unitless,
  // multiply it by the font size.
  if (!lineHeight.unit) {
    return _unitsCss2.default.parse("" + size.value * lineHeight.value + size.unit);
  }

  // units-css requires the user to provide
  // DOM nodes for these units. We don't want
  // to pollute our API with that for the time being.
  var unitBlacklist = ["%", "ch", "cm", "em", "ex"];
  if (unitBlacklist.indexOf(lineHeight.unit) !== -1) {
    // eslint-disable-line no-magic-numbers
    throw new Error("We do not currently support the unit " + lineHeight.unit + "\n      from the provided line-height " + lineHeight.value + ".\n      Unsupported units include " + unitBlacklist.join(", ") + ".");
  }

  // Otherwise, the height is equivalent
  // to the provided line height.
  // Non-px units need conversion.
  if (lineHeight.unit === "px") {
    return lineHeight;
  }
  return _unitsCss2.default.parse(_unitsCss2.default.convert(lineHeight, "px"));
};

var measureText = function measureText(_ref) {
  var text = _ref.text;
  var fontFamily = _ref.fontFamily;
  var fontSize = _ref.fontSize;
  var lineHeight = _ref.lineHeight;
  var _ref$fontWeight = _ref.fontWeight;
  var fontWeight = _ref$fontWeight === undefined ? DEFAULT_FONT_WEIGHT : _ref$fontWeight;
  var _ref$fontStyle = _ref.fontStyle;
  var fontStyle = _ref$fontStyle === undefined ? DEFAULT_FONT_STYLE : _ref$fontStyle;
  var _ref$canvas = _ref.canvas;
  var canvas = _ref$canvas === undefined ? DEFAULT_CANVAS : _ref$canvas;

  var ctx = canvas.getContext("2d");
  ctx.font = fontWeight + " " + fontStyle + " " + fontSize + " " + fontFamily;

  var measure = function measure(line) {
    return {
      text: line,
      width: _unitsCss2.default.parse(ctx.measureText(line).width + "px"),
      height: measureHeight(_unitsCss2.default.parse(fontSize), _unitsCss2.default.parse(lineHeight))
    };
  };

  // If multiline, measure the bounds
  // of all of the lines combined
  if (Array.isArray(text)) {
    return text.map(measure).reduce(function (prev, curr) {
      var width = curr.width.value > prev.width.value ? curr.width : prev.width;
      var height = _unitsCss2.default.parse("" + (prev.height.value + curr.height.value) + curr.height.unit);
      var longest = curr.text.length > prev.text.length ? curr.text : prev.text;
      return { width: width, height: height, text: longest };
    });
  }

  return measure(text);
};

exports.default = measureText;