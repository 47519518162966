"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Magnet = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M164.07 148.1H12a12 12 0 01-12-12v-80a36 36 0 0136-36h104a36 36 0 0136 36v80a11.89 11.89 0 01-11.93 12zm347.93-12V56a36 36 0 00-36-36H372a36 36 0 00-36 36v80a12 12 0 0012 12h152a11.89 11.89 0 0012-11.9zm-164 44a12 12 0 00-12 12v52c0 128.1-160 127.9-160 0v-52a12 12 0 00-12-12H12.1a12 12 0 00-12 12.1c.1 21.4.6 40.3 0 53.3 0 150.6 136.17 246.6 256.75 246.6s255-96 255-246.7c-.6-12.8-.2-33 0-53.2a12 12 0 00-12-12.1z", key: "k0" })));
});
exports.Magnet.displayName = 'Magnet';
exports.MagnetDimensions = { height: undefined, width: undefined };
