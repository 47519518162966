"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.SortAlphaUp = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M16 160h48v304a16 16 0 0016 16h32a16 16 0 0016-16V160h48c14.21 0 21.38-17.24 11.31-27.31l-80-96a16 16 0 00-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160zm400 128H288a16 16 0 00-16 16v32a16 16 0 0016 16h56l-61.26 70.45A32 32 0 00272 446.37V464a16 16 0 0016 16h128a16 16 0 0016-16v-32a16 16 0 00-16-16h-56l61.26-70.45A32 32 0 00432 321.63V304a16 16 0 00-16-16zm31.06-85.38l-59.27-160A16 16 0 00372.72 32h-41.44a16 16 0 00-15.07 10.62l-59.27 160A16 16 0 00272 224h24.83a16 16 0 0015.23-11.08l4.42-12.92h71l4.41 12.92A16 16 0 00407.16 224H432a16 16 0 0015.06-21.38zM335.61 144L352 96l16.39 48z", key: "k0" })));
});
exports.SortAlphaUp.displayName = 'SortAlphaUp';
exports.SortAlphaUpDimensions = { height: undefined, width: undefined };
