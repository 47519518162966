"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.SpellCheck = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 576 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M272 256h91.36c43.2 0 82-32.2 84.51-75.34a79.82 79.82 0 00-25.26-63.07 79.81 79.81 0 009.06-44.91C427.9 30.57 389.3 0 347 0h-75a16 16 0 00-16 16v224a16 16 0 0016 16zm40-200h40a24 24 0 010 48h-40zm0 96h56a24 24 0 010 48h-56zM155.12 22.25A32 32 0 00124.64 0H99.36a32 32 0 00-30.48 22.25L.59 235.73A16 16 0 0016 256h24.93a16 16 0 0015.42-11.73L68.29 208h87.42l11.94 36.27A16 16 0 00183.07 256H208a16 16 0 0015.42-20.27zM89.37 144L112 75.3l22.63 68.7zm482 132.48l-45.21-45.3a15.88 15.88 0 00-22.59 0l-151.5 151.5-55.41-55.5a15.88 15.88 0 00-22.59 0l-45.3 45.3a16 16 0 000 22.59l112 112.21a15.89 15.89 0 0022.6 0l208-208.21a16 16 0 00-.02-22.59z", key: "k0" })));
});
exports.SpellCheck.displayName = 'SpellCheck';
exports.SpellCheckDimensions = { height: undefined, width: undefined };
