"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.VideoSlash = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M633.8 458.1l-55-42.5c15.4-1.4 29.2-13.7 29.2-31.1v-257c0-25.5-29.1-40.4-50.4-25.8L448 177.3v137.2l-32-24.7v-178c0-26.4-21.4-47.8-47.8-47.8H123.9L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4L42.7 82 416 370.6l178.5 138c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.5-6.9 4.2-17-2.8-22.4zM32 400.2c0 26.4 21.4 47.8 47.8 47.8h288.4c11.2 0 21.4-4 29.6-10.5L32 154.7v245.5z", key: "k0" })));
});
exports.VideoSlash.displayName = 'VideoSlash';
exports.VideoSlashDimensions = { height: undefined, width: undefined };
