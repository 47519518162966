"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.WaveSquare = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M476 480H324a36 36 0 01-36-36V96h-96v156a36 36 0 01-36 36H16a16 16 0 01-16-16v-32a16 16 0 0116-16h112V68a36 36 0 0136-36h152a36 36 0 0136 36v348h96V260a36 36 0 0136-36h140a16 16 0 0116 16v32a16 16 0 01-16 16H512v156a36 36 0 01-36 36z", key: "k0" })));
});
exports.WaveSquare.displayName = 'WaveSquare';
exports.WaveSquareDimensions = { height: undefined, width: undefined };
