"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.SortAlphaDownAlt = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M176 352h-48V48a16 16 0 00-16-16H80a16 16 0 00-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0022.62 0l80-96C197.35 369.26 190.22 352 176 352zm112-128h128a16 16 0 0016-16v-32a16 16 0 00-16-16h-56l61.26-70.45A32 32 0 00432 65.63V48a16 16 0 00-16-16H288a16 16 0 00-16 16v32a16 16 0 0016 16h56l-61.26 70.45A32 32 0 00272 190.37V208a16 16 0 0016 16zm159.06 234.62l-59.27-160A16 16 0 00372.72 288h-41.44a16 16 0 00-15.07 10.62l-59.27 160A16 16 0 00272 480h24.83a16 16 0 0015.23-11.08l4.42-12.92h71l4.41 12.92A16 16 0 00407.16 480H432a16 16 0 0015.06-21.38zM335.61 400L352 352l16.39 48z", key: "k0" })));
});
exports.SortAlphaDownAlt.displayName = 'SortAlphaDownAlt';
exports.SortAlphaDownAltDimensions = { height: undefined, width: undefined };
