"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Atom = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 448 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M224 224a32 32 0 1032.007 32 32.064 32.064 0 00-32.008-32zm214.171-96c-10.877-19.5-40.51-50.75-116.275-41.875C300.392 34.875 267.634 0 223.999 0s-76.39 34.875-97.896 86.125C50.337 77.375 20.706 108.5 9.829 128-6.55 157.375-5.175 201.125 34.958 256-5.175 310.875-6.55 354.625 9.829 384c29.13 52.375 101.647 43.625 116.274 41.875C147.608 477.125 180.364 512 223.999 512s76.393-34.875 97.897-86.125c14.629 1.75 87.144 10.5 116.275-41.875 16.379-29.375 15.004-73.125-25.13-128 40.134-54.875 41.509-98.625 25.13-128zM63.34 352c-4-7.25-.125-24.75 15.004-48.25a368.519 368.519 0 0021.88 19.125c1.626 13.75 4 27.125 6.75 40.125-24.628.875-39.882-4.375-43.634-11zm36.885-162.875a368.506 368.506 0 00-21.881 19.125c-15.13-23.5-19.004-41-15.004-48.25 3.377-6.125 16.379-11.5 37.885-11.5 1.75 0 3.875.375 5.75.375a443.175 443.175 0 00-6.75 40.25zM223.999 64c9.502 0 22.256 13.5 33.883 37.25-11.252 3.75-22.504 8-33.883 12.875a434.638 434.638 0 00-33.883-12.875C201.746 77.5 214.497 64 224 64zm0 384c-9.502 0-22.254-13.5-33.883-37.25 11.254-3.75 22.506-8 33.883-12.875a435.23 435.23 0 0033.883 12.875C246.255 434.5 233.5 448 223.999 448zm0-112a80 80 0 1180-80 80 80 0 01-80 80zm160.66 16c-3.625 6.625-19.004 11.875-43.634 11 2.752-13 5.127-26.375 6.752-40.125a367.856 367.856 0 0021.878-19.125c15.13 23.5 19.004 41 15.004 48.25zm-15.004-143.75a367.869 367.869 0 00-21.879-19.125 442.488 442.488 0 00-6.752-40.25c1.875 0 4.002-.375 5.752-.375 21.504 0 34.508 5.375 37.883 11.5 4 7.25.125 24.75-15.004 48.25z", key: "k0" })));
});
exports.Atom.displayName = 'Atom';
exports.AtomDimensions = { height: undefined, width: undefined };
