"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.EyeSlash = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 640 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 000 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 01-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0081.25-102.07 32.35 32.35 0 000-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 00-147.32 37.7L45.46 3.37A16 16 0 0023 6.18L3.37 31.45A16 16 0 006.18 53.9l588.36 454.73a16 16 0 0022.46-2.81l19.64-25.27a16 16 0 00-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 00416 256a94.76 94.76 0 00-121.31-92.21A47.65 47.65 0 01304 192a46.64 46.64 0 01-1.54 10l-73.61-56.89A142.31 142.31 0 01320 112a143.92 143.92 0 01144 144c0 21.63-5.29 41.79-13.9 60.11z", key: "k0" })));
});
exports.EyeSlash.displayName = 'EyeSlash';
exports.EyeSlashDimensions = { height: undefined, width: undefined };
