"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.SatelliteDish = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M305.45 462.59c7.391 7.298 6.188 20.097-3 25.004-77.714 41.802-176.726 29.91-242.344-35.709-65.602-65.603-77.51-164.523-35.692-242.331 4.891-9.095 17.69-10.298 25.003-3l116.812 116.813 27.394-27.394c-.688-2.61-1.594-5.001-1.594-7.814a32.004 32.004 0 1132.004 32.005c-2.797 0-5.204-.891-7.798-1.594l-27.41 27.41zm206.526-159.523a16.103 16.103 0 01-16.002 17.003H463.86a15.97 15.97 0 01-15.892-15.002C440.467 175.549 336.453 70.534 207.03 63.533a15.845 15.845 0 01-15.002-15.908V16.027A16.094 16.094 0 01209.03.024C372.255 8.62 503.475 139.841 511.976 303.067zm-96.012-.297a16.21 16.21 0 01-16.112 17.3h-32.207a16.069 16.069 0 01-15.893-14.705c-6.907-77.011-68.118-138.91-144.924-145.224a15.94 15.94 0 01-14.8-15.893v-32.114a16.134 16.134 0 0117.3-16.096c110.123 8.501 198.228 96.607 206.636 206.732z", key: "k0" })));
});
exports.SatelliteDish.displayName = 'SatelliteDish';
exports.SatelliteDishDimensions = { height: undefined, width: undefined };
