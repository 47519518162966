"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Pager = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M448 64H64a64 64 0 00-64 64v256a64 64 0 0064 64h384a64 64 0 0064-64V128a64 64 0 00-64-64zM160 368H80a16 16 0 01-16-16v-16a16 16 0 0116-16h80zm128-16a16 16 0 01-16 16h-80v-48h80a16 16 0 0116 16zm160-128a32 32 0 01-32 32H96a32 32 0 01-32-32v-64a32 32 0 0132-32h320a32 32 0 0132 32z", key: "k0" })));
});
exports.Pager.displayName = 'Pager';
exports.PagerDimensions = { height: undefined, width: undefined };
