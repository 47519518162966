"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.ClinicMedical = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 576 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0016 16h416a16 16 0 0016-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2zm96 261a8 8 0 01-8 8h-56v56a8 8 0 01-8 8h-48a8 8 0 01-8-8v-56h-56a8 8 0 01-8-8v-48a8 8 0 018-8h56v-56a8 8 0 018-8h48a8 8 0 018 8v56h56a8 8 0 018 8zm186.69-139.72l-255.94-226a39.85 39.85 0 00-53.45 0l-256 226a16 16 0 00-1.21 22.6L25.5 282.7a16 16 0 0022.6 1.21L277.42 81.63a16 16 0 0121.17 0L527.91 283.9a16 16 0 0022.6-1.21l21.4-23.82a16 16 0 00-1.22-22.59z", key: "k0" })));
});
exports.ClinicMedical.displayName = 'ClinicMedical';
exports.ClinicMedicalDimensions = { height: undefined, width: undefined };
