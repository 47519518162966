"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Jedi = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 576 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M535.953 352c-42.64 94.172-137.64 160-247.985 160q-6.398 0-12.843-.297C171.155 506.938 81.265 442.234 40.015 352h39.922l-58.61-58.594a264.825 264.825 0 01-5.11-39.422 273.665 273.665 0 01.5-29.984h47.22l-41.391-41.375A269.798 269.798 0 01130.515 20.547a16.064 16.064 0 019.281-3 16.363 16.363 0 0113.5 7.25 16.027 16.027 0 011.625 15.094 138.387 138.387 0 00-9.844 51.265c0 45.11 21.047 86.578 57.72 113.735a16.3 16.3 0 011.202 25.39c-26.547 23.985-41.172 56.5-41.172 91.578 0 60.032 42.954 110.282 99.891 121.922l2.5-65.265L238.062 397a8.34 8.34 0 01-10-.75 8.025 8.025 0 01-1.39-9.938l20.124-33.765-42.062-8.735a7.99 7.99 0 010-15.656l42.062-8.719-20.109-33.734a7.991 7.991 0 0111.36-10.719l30.39 20.657L279.953 7.67a7.971 7.971 0 018-7.671H288a8.02 8.02 0 017.953 7.703l11.53 287.922 30.392-20.672a8.083 8.083 0 0110 .813 7.999 7.999 0 011.39 9.906l-20.14 33.765 42.078 8.735a7.994 7.994 0 010 15.656l-42.078 8.719 20.109 33.734a7.978 7.978 0 01-1.328 9.922 8.257 8.257 0 01-10.031.797l-27.172-18.469 2.5 65.344c48.484-9.406 87.578-48.156 97.312-96.5a123.527 123.527 0 00-38.562-117.078 16.306 16.306 0 011.203-25.422c36.656-27.172 57.688-68.61 57.688-113.734a138.017 138.017 0 00-9.86-51.313 15.981 15.981 0 011.61-15.094 16.37 16.37 0 0113.5-7.234 16.025 16.025 0 019.25 2.984A271.27 271.27 0 01553.25 182.766L512 224h46.953c.36 5.766 1.047 11.453 1.047 17.266a270.092 270.092 0 01-5.125 51.859L495.985 352z", key: "k0" })));
});
exports.Jedi.displayName = 'Jedi';
exports.JediDimensions = { height: undefined, width: undefined };
