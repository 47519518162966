"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Sync = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M440.65 12.57l4 82.77A247.16 247.16 0 00255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0024.09 224h49.05a12 12 0 0011.67-9.26 175.91 175.91 0 01317-56.94l-101.46-4.86a12 12 0 00-12.57 12v47.41a12 12 0 0012 12H500a12 12 0 0012-12V12a12 12 0 00-12-12h-47.37a12 12 0 00-11.98 12.57zM255.83 432a175.61 175.61 0 01-146-77.8l101.8 4.87a12 12 0 0012.57-12v-47.4a12 12 0 00-12-12H12a12 12 0 00-12 12V500a12 12 0 0012 12h47.35a12 12 0 0012-12.6l-4.15-82.57A247.17 247.17 0 00255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 00-11.8-14.18h-49.05a12 12 0 00-11.67 9.26A175.86 175.86 0 01255.83 432z", key: "k0" })));
});
exports.Sync.displayName = 'Sync';
exports.SyncDimensions = { height: undefined, width: undefined };
