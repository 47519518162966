"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = tslib_1.__importStar(require("react"));
var styled_icon_1 = require("@styled-icons/styled-icon");
exports.Chess = React.forwardRef(function (props, ref) {
    var attrs = {
        "fill": "currentColor",
        "xmlns": "http://www.w3.org/2000/svg",
    };
    return (React.createElement(styled_icon_1.StyledIconBase, tslib_1.__assign({ iconAttrs: attrs, iconVerticalAlign: "-.125em", iconViewBox: "0 0 512 512" }, props, { ref: ref }),
        React.createElement("path", { fill: "currentColor", d: "M74 208H64a16 16 0 00-16 16v16a16 16 0 0016 16h15.94A535.78 535.78 0 0164 384h128a535.78 535.78 0 01-15.94-128H192a16 16 0 0016-16v-16a16 16 0 00-16-16h-10l33.89-90.38a16 16 0 00-15-21.62H144V64h24a8 8 0 008-8V40a8 8 0 00-8-8h-24V8a8 8 0 00-8-8h-16a8 8 0 00-8 8v24H88a8 8 0 00-8 8v16a8 8 0 008 8h24v32H55.09a16 16 0 00-15 21.62zm173.16 251.58L224 448v-16a16 16 0 00-16-16H48a16 16 0 00-16 16v16L8.85 459.58A16 16 0 000 473.89V496a16 16 0 0016 16h224a16 16 0 0016-16v-22.11a16 16 0 00-8.84-14.31zm92.77-157.78l-3.29 82.2h126.72l-3.29-82.21 24.6-20.79A32 32 0 00496 256.54V198a6 6 0 00-6-6h-26.38a6 6 0 00-6 6v26h-24.71v-26a6 6 0 00-6-6H373.1a6 6 0 00-6 6v26h-24.71v-26a6 6 0 00-6-6H310a6 6 0 00-6 6v58.6a32 32 0 0011.36 24.4zM384 304a16 16 0 0132 0v32h-32zm119.16 155.58L480 448v-16a16 16 0 00-16-16H336a16 16 0 00-16 16v16l-23.15 11.58a16 16 0 00-8.85 14.31V496a16 16 0 0016 16h192a16 16 0 0016-16v-22.11a16 16 0 00-8.84-14.31z", key: "k0" })));
});
exports.Chess.displayName = 'Chess';
exports.ChessDimensions = { height: undefined, width: undefined };
